export const timezones = [
  {
    label: 'UTC -12',
    value: -720,
  },
  {
    label: 'UTC -11',
    value: -660,
  },
  {
    label: 'UTC -10',
    value: -600,
  },
  {
    label: 'UTC -9',
    value: -540,
  },
  {
    label: 'UTC -8',
    value: -480,
  },
  {
    label: 'UTC -7',
    value: -420,
  },
  {
    label: 'UTC -6',
    value: -360,
  },
  {
    label: 'UTC -5',
    value: -300,
  },
  {
    label: 'UTC -4',
    value: -240,
  },
  {
    label: 'UTC -3',
    value: -180,
  },
  {
    label: 'UTC -2',
    value: -120,
  },
  {
    label: 'UTC -1',
    value: -60,
  },
  {
    label: 'UTC 0',
    value: 0,
  },
  {
    label: 'UTC +1',
    value: 60,
  },
  {
    label: 'UTC +2',
    value: 120,
  },
  {
    label: 'UTC +3',
    value: 180,
  },
  {
    label: 'UTC +4',
    value: 240,
  },
  {
    label: 'UTC +5',
    value: 300,
  },
  {
    label: 'UTC +6',
    value: 360,
  },
  {
    label: 'UTC +7',
    value: 420,
  },
  {
    label: 'UTC +8',
    value: 480,
  },
  {
    label: 'UTC +9',
    value: 540,
  },
  {
    label: 'UTC +10',
    value: 600,
  },
  {
    label: 'UTC +11',
    value: 660,
  },
  {
    label: 'UTC +12',
    value: 720,
  },
];

export const dateFilterFields = [
  {
    label: 'today',
    value: 1,
  },
  {
    label: 'yesterday',
    value: 2,
  },
  {
    label: 'last-7-days',
    value: 3,
  },
  {
    label: 'last-30-days',
    value: 4,
  },
];

export const dftM: { [key: string]: number } = {
  '1': 0,
  '2': 1,
  '3': 7,
  '4': 30,
  '5': 90,
};

export const dateFormat = 'MM-DD-YYYY';

export const gameRateLimits: {
  [key: number]: {
    [key: number]: { [key: string]: { min: number; max: number } };
    // | { min: number; max: number };
  };
} = {
  1: { 24: { default: { min: 16.6, max: 99 } } },
  2: { 27: { default: { min: 25, max: 99 } } },
  4: {
    29: {
      left: { min: 36.33, max: 99 },
      tie: { min: 27.34, max: 99 },
      right: { min: 36.33, max: 99 },
    },
    30: {
      left: { min: 47.26, max: 99 },
      tie: { min: 5.46, max: 99 },
      right: { min: 47.26, max: 99 },
    },
    31: {
      odd: { min: 49.8, max: 99 },
      even: { min: 49.8, max: 99 },
      over: { min: 49.8, max: 99 },
      under: { min: 49.8, max: 99 },
      0: { min: 0.39, max: 99 },
    },
    32: {
      odd: { min: 49.8, max: 99 },
      even: { min: 49.8, max: 99 },
      over: { min: 49.8, max: 99 },
      under: { min: 49.8, max: 99 },
      0: { min: 0.39, max: 99 },
    },
    33: {
      odd: { min: 50, max: 99 },
      even: { min: 49.6, max: 99 },
      0: { min: 0.39, max: 99 },
    },
    34: {
      odd: { min: 50, max: 99 },
      even: { min: 49.6, max: 99 },
      0: { min: 0.39, max: 99 },
    },
  },
};

//old limits
// 1: { 24: { min: 16.6, max: 99 } },
// 2: { 27: { min: 25, max: 99 } },
// 4: {
//   29: { min: 27.34, max: 99 },
//   30: { min: 5.46, max: 99 },
//   31: { min: 0.39, max: 99 },
//   32: { min: 0.39, max: 99 },
//   33: { min: 0.39, max: 99 },
//   34: { min: 0.39, max: 99 },
// },

export const gameFilters = (t: Function) => {
  return [
    { value: '', label: t('all-games') },
    { value: 'Space 8', label: t('space-8') },
    { value: 'Speed 6', label: t('speed-6') },
    { value: 'Speed 4', label: t('speed-4') },
    { value: 'Maze 2', label: t('maze-2') },
  ];
};

export const marketFilters = (t: Function) => {
  return [
    {
      games: ['Space 8', 'Speed 6', 'Speed 4', 'Maze 2'],
      value: '',
      label: t('all-markets'),
    },
    {
      games: ['Speed 6', 'Speed 4', 'Maze 2'],
      value: '1st Ball',
      label: t('1st ball'),
    },
    {
      games: ['Speed 6', 'Speed 4'],
      value: 'Top 3 Sum',
      label: t('top 3 sum'),
    },
    {
      games: ['Speed 6', 'Speed 4'],
      value: 'ChampionBall',
      label: t('championball'),
    },
    {
      games: ['Space 8'],
      value: 'Ball Count',
      label: t('ball count'),
    },
    {
      games: ['Space 8'],
      value: 'Ball Sum',
      label: t('ball sum'),
    },
    {
      games: ['Space 8'],
      value: 'Left First Ball',
      label: t('left first ball'),
    },
    {
      games: ['Space 8'],
      value: 'Right First Ball',
      label: t('right first ball'),
    },
    {
      games: ['Space 8'],
      value: 'Left Ball Sum',
      label: t('left ball sum'),
    },
    {
      games: ['Space 8'],
      value: 'Right Ball Sum',
      label: t('right ball sum'),
    },
  ];
};

export const priceFilters = (t: Function) => {
  return [
    {
      games: ['Space 8', 'Speed 6', 'Speed 4', 'Maze 2'],
      value: '',
      label: t('all-prices'),
    },
    {
      games: ['Space 8', 'Speed 6', 'Speed 4', 'Maze 2'],
      market: ['1st Ball', 'Top 3 Sum', 'Left First Ball', 'Right First Ball'],
      value: 'Odd',
      label: t('odd'),
    },
    {
      games: ['Space 8', 'Speed 6', 'Speed 4', 'Maze 2'],
      market: ['1st Ball', 'Top 3 Sum', 'Left First Ball', 'Right First Ball'],
      value: 'Even',
      label: t('even'),
    },
    {
      games: ['Space 8', 'Speed 6', 'Speed 4'],
      market: ['1st Ball', 'Top 3 Sum', 'Left First Ball', 'Right First Ball'],
      value: 'Over',
      label: t('over'),
    },
    {
      games: ['Space 8', 'Speed 6', 'Speed 4'],
      market: ['1st Ball', 'Top 3 Sum', 'Left First Ball', 'Right First Ball'],
      value: 'Under',
      label: t('under'),
    },
    {
      games: ['Speed 6', 'Speed 4'],
      market: ['ChampionBall'],
      value: 'Ball',
      label: t('ball'),
    },
    {
      games: ['Speed 6', 'Speed 4'],
      market: ['ChampionBall'],
      value: 'Ball 1',
      label: `${t('ball')} 1`,
    },
    {
      games: ['Speed 6', 'Speed 4'],
      market: ['ChampionBall'],
      value: 'Ball 2',
      label: `${t('ball')} 2`,
    },
    {
      games: ['Speed 6', 'Speed 4'],
      market: ['ChampionBall'],
      value: 'Ball 3',
      label: `${t('ball')} 3`,
    },
    {
      games: ['Speed 6', 'Speed 4'],
      market: ['ChampionBall'],
      value: 'Ball 4',
      label: `${t('ball')} 4`,
    },
    {
      games: ['Speed 6'],
      market: ['ChampionBall'],
      value: 'Ball 5',
      label: `${t('ball')} 5`,
    },
    {
      games: ['Speed 6'],
      market: ['ChampionBall'],
      value: 'Ball 6',
      label: `${t('ball')} 6`,
    },
    {
      games: ['Space 8'],
      market: ['Ball Count', 'Ball Sum'],
      value: 'Left',
      label: t('left'),
    },
    {
      games: ['Space 8'],
      market: ['Ball Count', 'Ball Sum'],
      value: 'Right',
      label: t('right'),
    },
    {
      games: ['Space 8'],
      market: ['Ball Count', 'Ball Sum'],
      value: 'Tie',
      label: t('tie'),
    },
    // {
    //   games: ['Space 8'],
    //   market: [
    //     'Left First Ball',
    //     'Right First Ball',
    //     'Left Ball Sum',
    //     'Right Ball Sum',
    //   ],
    //   value: 'Odd',
    //   label: t('odd'),
    // },
    // {
    //   games: ['Space 8'],
    //   market: [
    //     'Left First Ball',
    //     'Right First Ball',
    //     'Left Ball Sum',
    //     'Right Ball Sum',
    //   ],
    //   value: 'Even',
    //   label: t('even'),
    // },
    // {
    //   games: ['Space 8'],
    //   market: ['Left First Ball', 'Right First Ball'],
    //   value: 'Over',
    //   label: t('over'),
    // },
    // {
    //   games: ['Space 8'],
    //   market: ['Left First Ball', 'Right First Ball'],
    //   value: 'Under',
    //   label: t('under'),
    // },
    {
      games: ['Space 8'],
      market: [
        'Left First Ball',
        'Right First Ball',
        'Left Ball Sum',
        'Right Ball Sum',
      ],
      value: '0',
      label: t('0'),
    },
  ];
};
