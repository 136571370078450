import { RootState } from "..";

export const selectUser = (state: RootState) => state.user.profile;
export const selectTwoFA = (state: RootState) => state.user.twoFA;
export const selectUserToken = (state: RootState) => state.user.token;
export const selectPassToken = (state: RootState) => state.user.changePasswordToken;
export const selectLoader = (state: RootState) => state.user.loading;
export const selectMessage = (state: RootState) => state.user.message;
export const selectCurrent = (state: RootState) => state.user.current;

